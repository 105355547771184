import axios from "axios";
import { V_URL } from "../../../BaseUrl";
import toast from "react-hot-toast";

export const DownloadPdf = ({ apiMethod, url, body }) => {
    const myurl = `${V_URL}/user/${url}`;
    axios({
        method: apiMethod,
        data: body,
        url: myurl,
        headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
    }).then((response) => {
        if (response?.data?.success === true) {
            const fileUrl = response.data.data.file;
            window.open(fileUrl, '_blank');
            toast.success(response?.data.message);
        } else {
            toast.error(response?.data.message);
        }
    }).catch((error) => {
        console.log(error, '!!');
        toast.error(error?.response?.data.message);
    })
}