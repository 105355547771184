import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast';
import axios from 'axios';
import { M_STORE, P_STORE, PAY, PMS, V_URL } from '../../../BaseUrl';
import cryptojs from 'crypto-js'
import moment from 'moment';

const Login = () => {

    const [login, setLogin] = useState({ email: "", password: "", pwdShow: true, remember: false });
    const [newData, setNewData] = useState({ email: "", product: "", erp_role: "", project: "", year: '', firm: '' });
    const [projectData, setProjectData] = useState([]);
    const [yearData, setYearData] = useState([]);
    const [firmData, setFirmData] = useState([]);
    const [disable, setDisable] = useState(false);
    const [error, setError] = useState({});
    const [errorTwo, setErrorTwo] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const secretKey = 'Vishal Enterprise';

    useEffect(() => {
        if (localStorage.getItem('PAY_USER_REMEMBER_ME') && localStorage.getItem('PAY_USER_REMEMBER_ME') === 'true') {
            const password = cryptojs.AES.decrypt(localStorage.getItem('PAY_USER_PASSWORD'), secretKey);
            const decryptedPassword = password.toString(cryptojs.enc.Utf8);
            setLogin({
                email: localStorage.getItem('PAY_USER_EMAIL'),
                password: decryptedPassword,
                remember: true,
                pwdShow: true
            })
        }
    }, []);

    const handleChange = (e) => {
        setLogin({ ...login, [e.target.name]: e.target.value });
    };

    const handleChangeTwo = (e) => {
        setNewData({ ...newData, [e.target.name]: e.target.value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validation()) {
            setDisable(true);
            const myurl = `${V_URL}/user/login`;
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('email', login.email);
            bodyFormData.append('password', login.password);
            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
            }).then((response) => {
                console.log(response?.data, '@@@');
                const data = response.data.data
                if (data?.product !== `${PAY}`) {
                    if (response.data.success === true) {
                        setFirmData(data?.firm);
                        setYearData(data?.year);
                        setProjectData(data?.project);
                        setNewData({
                            email: data?.email,
                            product: data?.product,
                            erp_role: data?.erpRole?.name
                        });

                        localStorage.setItem('VI_PRO', data?.product);
                        if (data?.erpRole?.name) {
                            localStorage.setItem('ERP_ROLE', data?.erpRole?.name);
                        }
                        localStorage.setItem('PAY_USER_TOKEN', data?.token);
                        localStorage.setItem('PAY_USER_NAME', data?.name);
                        localStorage.setItem('PAY_USER_ID', data?.id);
                        localStorage.setItem('PAY_USER_EMAIL', data?.email);

                        toast.success(response?.data?.message);
                    }

                    if (login?.remember) {
                        localStorage.setItem('PAY_USER_EMAIL', login?.email);
                        localStorage.setItem('PAY_USER_PASSWORD', cryptojs.AES.encrypt(login?.password, secretKey).toString());
                        localStorage.setItem('PAY_USER_REMEMBER_ME', login?.remember);
                    } else {
                        localStorage.setItem('PAY_USER_EMAIL', login?.email);
                        localStorage.setItem('PAY_USER_PASSWORD', cryptojs.AES.encrypt(login?.password, secretKey).toString());
                        localStorage.setItem('PAY_USER_REMEMBER_ME', login?.remember);
                    }

                    setDisable(false);
                    // setLogin({ email: "", password: "", pwdShow: true });    
                } else {
                    toast.error('Please return to the previous page and select the payroll user.')
                    setDisable(false);
                }
            }).catch((error) => {
                console.log(error, "error");
                toast.error(error?.response?.data?.message || 'Something went wrong')
                setDisable(false);
            })
        }
    }

    console.log(location.state, 'Project Selected Successfully')


    const handleSignIn = (e) => {
        e.preventDefault();
        if (validationTwo()) {
            setDisable(true);
            // if (firmData.length > 0 || yearData.length > 0) {
            const selectedProject = projectData?.find(p => p?._id === newData?.project);
            if (firmData.length > 0 || yearData.length > 0) {
                localStorage.setItem('PAY_USER_YEAR_ID', newData?.year);
                localStorage.setItem('PAY_USER_FIRM_ID', newData?.firm);
            }
            if (projectData?.length > 0) {
                localStorage.setItem('PAY_USER_YEAR_ID', selectedProject?.year_id?._id);
                localStorage.setItem('PAY_USER_FIRM_ID', selectedProject?.firm_id?._id);
                localStorage.setItem('U_PROJECT_ID', newData?.project);
            }
            if (newData.product === `${M_STORE}` && location.state?.type === true) {
                navigate('/main-store/user/dashboard');
                toast.success("Data Selected Successfully");
            } else if ((newData.product === `${PMS}`) && location.state?.type !== true) {
                navigate('/user/project-store/dashboard');
                toast.success("Data Selected Successfully");
            } else {
                navigate('/');
                toast.error("Access denied or invalid step. Please try again.");
            }
            //     navigate('/product-store/user/dashboard');
            // } else if (newData.product === `${ERP}`) {
            //     if (newData.erp_role === `${PLAN}`) {
            //         navigate('/erp/user/planner/dashboard');
            //     } else if (newData.erp_role === `${QC}`) {
            //         navigate('/erp/user/qc/dashboard');
            //     } else if (newData.erp_role === `${M_CON}`) {
            //         navigate('/erp/user/material-controller/dashboard');
            //     }
            // }'   
            setDisable(false);
            // } else {
            //     toast.error("Some error occurred.");
            //     setDisable(false);
            // }
        }
    }

    const validationTwo = () => {
        var isValid = true;
        let err = {};

        if (projectData?.length > 0) {
            if (!newData.project) {
                isValid = false;
                err['project_err'] = "Please select project"
            }
        }

        if (firmData?.length > 0 || yearData?.length > 0) {
            if (!newData.firm) {
                isValid = false;
                err['firm_err'] = "Please select firm"
            }
            if (!newData.year) {
                isValid = false;
                err['year_err'] = "Please select year"
            }
        }

        setErrorTwo(err);
        return isValid;
    }

    const validation = () => {
        var isValid = true;
        let err = {};

        if (!login.email) {
            isValid = false;
            err['email_err'] = "Please enter email"
        }

        if (!login.password) {
            isValid = false;
            err['password_err'] = "Please enter password"
        }
        setError(err);
        return isValid;
    }


    return (
        <div className="main-wrapper login-body">
            <div className="container-fluid px-0">
                <div className="row">
                    <div className="col-lg-6 login-wrap">
                        <div className="login-sec">
                            <div className="log-img">
                                <img className="img-fluid" src="/assets/img/login-img.png" alt="Logo" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 login-wrap-bg">
                        <div className="login-wrapper">
                            <div className="loginbox">
                                <div className="login-right">
                                    <div className="login-right-wrap">
                                        <div className="account-logo login-vlogo">
                                            <Link to="/user/login"><img src="/assets/img/login-vlogo.svg" alt="account-logo" /></Link>
                                        </div>
                                        {/* {projectData?.length === 0 ? (
                                            <>
                                                <h2>User Login</h2>
                                                <form onSubmit={handleSubmit}>
                                                    <div className="input-block">
                                                        <label>Email <span className="login-danger">*</span></label>
                                                        <input className="form-control" onChange={handleChange} name='email'
                                                            type="email" value={login.email} />
                                                        <div className='error'>{error.email_err}</div>
                                                    </div>
                                                    <div className="input-block position-relative">
                                                        <label>Password <span className="login-danger">*</span></label>
                                                        <input className="form-control pass-input " type={login.pwdShow ? "password" : "text"}
                                                            value={login.password} onChange={handleChange} name='password' id='password' />
                                                        {login.pwdShow ? <div className="profile-views toggle-password" onClick={() => { setLogin({ ...login, pwdShow: false }) }}><i className='fa-solid fa-eye' /></div> : <div className='profile-views toggle-password' onClick={() => setLogin({ ...login, pwdShow: true })}><i className='fa-solid fa-eye-slash' /> </div>}
                                                        <div className='error'>{error.password_err}</div>
                                                    </div>
                                                    <div className="forgotpass">
                                                        <div className="remember-me">
                                                            <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                                                                Remember me
                                                                <input type="checkbox" name="radio" className='form-check-input' checked={login.remember} onChange={() => setLogin({ ...login, remember: !login.remember })} />
                                                                <span className="checkmark" />
                                                            </label>
                                                        </div>
                                                        <Link to="/user/forget-password">Forgot Password?</Link>
                                                    </div>
                                                    <div className="input-block login-btn">
                                                        <button className="btn-block  btn btn-primary" type="submit" disabled={disable}>{disable ? "Processing..." : "Continue"}</button>
                                                    </div>
                                                </form>
                                                <div className="next-sign">
                                                    <p className="account-subtitle">Back To <Link to="/">Vishal Login</Link>
                                                    </p>
                                                </div>
                                            </>
                                        ) : null} */}

                                        {projectData.length === 0 && (newData.product !== PMS && newData.product !== M_STORE) ? (
                                            <>
                                                <h2>{location?.state?.type === true ? 'Main Store' : 'PMS'} Login</h2>
                                                <form onSubmit={handleSubmit}>
                                                    <div className="input-block">
                                                        <label>Email <span className="login-danger">*</span></label>
                                                        <input className="form-control" onChange={handleChange} name='email'
                                                            type="email" value={login.email} />
                                                        <div className='error'>{error.email_err}</div>
                                                    </div>
                                                    <div className="input-block position-relative">
                                                        <label>Password <span className="login-danger">*</span></label>
                                                        <input className="form-control pass-input " type={login.pwdShow ? "password" : "text"}
                                                            value={login.password} onChange={handleChange} name='password' id='password' />
                                                        {login.pwdShow ? <div className="profile-views toggle-password" onClick={() => { setLogin({ ...login, pwdShow: false }) }}><i className='fa-solid fa-eye' /></div> : <div className='profile-views toggle-password' onClick={() => setLogin({ ...login, pwdShow: true })}><i className='fa-solid fa-eye-slash' /> </div>}
                                                        <div className='error'>{error.password_err}</div>
                                                    </div>
                                                    <div className="forgotpass">
                                                        <div className="remember-me">
                                                            <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                                                                Remember me
                                                                <input type="checkbox" name="radio" className='form-check-input' checked={login.remember} onChange={() => setLogin({ ...login, remember: !login.remember })} />
                                                                <span className="checkmark" />
                                                            </label>
                                                        </div>
                                                        <Link to="/user/forget-password">Forgot Password?</Link>
                                                    </div>
                                                    <div className="input-block login-btn">
                                                        <button className="btn-block  btn btn-primary" type="submit" disabled={disable}>{disable ? "Processing..." : "Continue"}</button>
                                                    </div>
                                                </form>
                                                <div className="next-sign">
                                                    <p className="account-subtitle">Back To <Link to="/">Main Login</Link>
                                                    </p>
                                                </div>
                                            </>
                                        ) : null}

                                        {projectData?.length > 0 ? (
                                            <>
                                                <h2>User Login</h2>
                                                <form onSubmit={handleSignIn}>
                                                    <div className="input-block">
                                                        <label>Project  <span className="login-danger">*</span></label>
                                                        <select className="form-control select"
                                                            value={newData.project}
                                                            onChange={handleChangeTwo} name='project'
                                                        >
                                                            <option value="">Select Project</option>
                                                            {projectData?.map((e) =>
                                                                <option value={e?._id} key={e?._id}>{e?.name}</option>
                                                            )}
                                                        </select>
                                                        <div className='error'>{errorTwo.project_err}</div>
                                                    </div>
                                                    <div className="input-block login-btn">
                                                        <button className="btn-block  btn btn-primary" type="submit" disabled={disable}>{disable ? "Processing..." : "Sign in"}</button>
                                                    </div>
                                                </form>
                                            </>
                                        ) : (firmData?.length > 0 || yearData?.length > 0) ? (
                                            <>
                                                <h2>User Login</h2>
                                                <form onSubmit={handleSignIn}>
                                                    <div className="input-block">
                                                        <label>Firm<span className="login-danger">*</span></label>
                                                        <select className="form-control select"
                                                            value={newData.firm}
                                                            onChange={handleChangeTwo} name='firm'
                                                        >
                                                            <option value="">Select Firm</option>
                                                            {firmData?.map((e) =>
                                                                <option value={e?._id} key={e?._id}>{e?.name}</option>
                                                            )}
                                                        </select>
                                                        <div className='error'>{errorTwo.firm_err}</div>
                                                    </div>
                                                    <div className="input-block">
                                                        <label>Year<span className="login-danger">*</span></label>
                                                        <select className="form-control select"
                                                            value={newData.year}
                                                            onChange={handleChangeTwo} name='year'
                                                        >
                                                            <option value="">Select Year</option>
                                                            {yearData?.map((e) =>
                                                                <option value={e?._id} key={e?._id}>{moment(e?.start_year).format('YYYY')}-{moment(e?.end_year).format('YYYY')}</option>
                                                            )}
                                                        </select>
                                                        <div className='error'>{errorTwo.year_err}</div>
                                                    </div>
                                                    <div className="input-block login-btn">
                                                        <button className="btn-block  btn btn-primary" type="submit" disabled={disable}>{disable ? "Processing..." : "Sign in"}</button>
                                                    </div>
                                                </form>
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login