import React from 'react'
import { Pencil, Trash2 } from 'lucide-react';

const DrawSectionTable = ({ transactionData, handleSave, handleDelete, handleEdit, finalId, dataId }) => {

    return (
        <div className="card">
            <div className="card-body">
                <div className="col-12 d-flex justify-content-between align-items-center mb-4">
                    <div className="form-heading">
                        <h4>Section Details List</h4>
                    </div>
                    {(finalId || dataId) && (
                        <div className="add-group">
                            <button
                                type="button"
                                onClick={handleSave}
                                className="btn btn-primary add-pluss ms-2"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Add Material">
                                <img src="/assets/img/icons/plus.svg" alt="add-icon" />
                            </button>
                        </div>
                    )}
                </div>
                {transactionData?.length > 0 ? (
                    <div className="table-responsive">
                        <table className="table border-0 mb-0 custom-table table-striped comman-table">
                            <thead>
                                <tr>
                                    <th>Sr.</th>
                                    <th>Section Details</th>
                                    <th>Grid No.</th>
                                    <th>Item No.</th>
                                    <th>Qty.</th>
                                    <th>Length(mm)</th>
                                    <th>Width(mm)</th>
                                    <th>Item Weight(kg)</th>
                                    <th>Assem. Weight(kg)</th>
                                    <th>ASM(sqm)</th>
                                    <th className="text-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactionData?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.itemName?.name}</td>
                                        <td>{item.grid_no}</td>
                                        <td>{item.item_no}</td>
                                        <td>{item.quantity}</td>
                                        <td>{item.item_length}</td>
                                        <td>{item.item_width}</td>
                                        <td>{item.item_weight}</td>
                                        <td>{item.assembly_weight}</td>
                                        <td>{item.assembly_surface_area}</td>
                                        <td className="d-flex justify-content-end">
                                            <a className='action-icon mx-1' style={{ cursor: "pointer" }}
                                                data-toggle="tooltip" data-placement="top" title="Edit"
                                                onClick={() => handleEdit(item)}>
                                                <Pencil />
                                            </a>
                                            <a className='action-icon mx-1' style={{ cursor: "pointer" }}
                                                data-toggle="tooltip" data-placement="top" title="Delete"
                                                onClick={() => handleDelete(item?._id, item.itemName?.name)}>
                                                <Trash2 />
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : <p>"No section details found. You can add new sections by clicking the 'plus (+)' button."</p>}
            </div>
        </div >
    )
}

export default DrawSectionTable