import React, { useEffect, useMemo, useState } from "react";
import Header from "../../Include/Header";
import Sidebar from "../../Include/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { M_STORE, V_URL } from "../../../../BaseUrl";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../../Include/Loader";
import { Pagination, Search } from "../../Table";
import DropDown from "../../../../Components/DropDown";
import Swal from "sweetalert2";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import OrderModal from "../../../../Components/Transaction/OrderModal";
import { DownloadPdf } from "../../Components/DownloadPdf";
import { getOrder } from "../../../../Store/Store/Order/Order";
import { DownloadXlsx } from "../../Components/DownloadXlsx";

const IssuePurchaseReturn = () => {
  const navigate = useNavigate();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [limit, setlimit] = useState(10);
  const [disable, setDisable] = useState(true);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [filter, setFilter] = useState({
    date: {
      start: null,
      end: null
    }
  });

  useEffect(() => {
    if (localStorage.getItem('PAY_USER_TOKEN') === null) {
      navigate("/user/login");
    } else if (localStorage.getItem('VI_PRO') !== `${M_STORE}`) {
      toast.error('Access Denied. You do not have permission to view this product. Please contact your administrator for assistance.')
      navigate("/user/login");
    }
    fetchData()
  }, [navigate, disable, search, filter]);

  const fetchData = () => {
    const bodyFormData = new URLSearchParams();
    bodyFormData.append("tag_number", 14);
    bodyFormData.append("search", search);
    bodyFormData.append("filter", JSON.stringify(filter));
    dispatch(getOrder({ formData: bodyFormData }));
    setDisable(false);
  };

  const entity = useSelector((state => state?.getPurchaseorder?.user?.data))

  const commentsData = useMemo(() => {
    let computedComments = entity;
    if (search) {
      computedComments = computedComments.filter((pro) =>
        pro.project?.name?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
        pro.voucher_no?.toLowerCase()?.includes(search?.toLowerCase()) ||
        pro.bill_no?.toString()?.toLowerCase()?.includes(search.toLowerCase())
      );
    }
    setTotalItems(computedComments?.length);
    return computedComments?.slice(
      (currentPage - 1) * limit,
      (currentPage - 1) * limit + limit
    );
  }, [currentPage, search, limit, entity]);

  const itemApiData = useSelector((state) => state?.getItem?.user?.data);

  const handleDelete = (id, title) => {
    Swal.fire({
      title: `Are you sure want to delete ${title}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const myurl = `${V_URL}/user/delete-ms-transaction`;
        var bodyFormData = new URLSearchParams();
        bodyFormData.append("id", id);
        bodyFormData.append("tag_number", 14);

        axios({
          method: "delete",
          url: myurl,
          data: bodyFormData,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Barrer " + localStorage.getItem("PAY_USER_TOKEN"),
          },
        }).then((response) => {
          console.log(response.data, "DEL");
          if (response.data.success === true) {
            fetchData();
            toast.success(response?.data?.message);
            setDisable(true);
          } else {
            toast.error(response?.data?.message);
          }
        }).catch((error) => {
          toast.error(error?.response?.data?.message || "Something went wrong");
          console?.log("Errors", error);
        });
      }
    });
  };

  const handleDateChange = (e, type) => {
    const dateValue = e.target.value;
    setFilter(prevFilter => {
      const newFilter = {
        ...prevFilter,
        date: {
          ...prevFilter.date,
          [type]: dateValue
        }
      }
      return newFilter;
    });
  }

  const handleClose = () => setShow(false);
  const handleRefresh = () => {
    setDisable(true);
    setSearch('');
    setFilter({
      date: {
        start: null,
        end: null
      }
    })
  };

  const handleSaveModal = (data) => {
    // console.log(data, '%%%');
    const myurl = `${V_URL}/user/manage-order-adjustment`;
    const bodyFormData = new URLSearchParams();
    bodyFormData.append('order', data?.orderId);
    bodyFormData.append('itemName', data?.itemId);
    bodyFormData.append('balance_qty', data?.balance_qty);
    bodyFormData.append('receive_qty', data?.receive);
    bodyFormData.append('tag', '1');
    bodyFormData.append('store_type', '1');

    axios({
      method: 'post',
      url: myurl,
      data: bodyFormData,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') }
    }).then((response) => {
      console.log(response?.data, '@@')
      if (response?.data?.success === true) {
        toast.success(response?.data?.message);
        setShow(false);
        data.receive = '';
        setDisable(true);
      } else {
        toast.error(response?.data?.message);
      }
    }).catch((error) => {
      console.log(error, '!!');
      toast.error(error.response?.data?.message)
    })
  }

  const handleDownloadPdf = (id) => {
    const bodyFormData = new URLSearchParams();
    bodyFormData.append('id', id);
    bodyFormData.append('print_date', true);
    DownloadPdf({ apiMethod: 'post', url: 'one-ms-transaction-download', body: bodyFormData })
  }

  const handleDownloadAllPdf = () => {
    const bodyFormData = new URLSearchParams();
    bodyFormData.append('print_date', true);
    bodyFormData.append('search', search);
    bodyFormData.append('tag_number', 14);
    bodyFormData.append('filter', JSON.stringify(filter));
    DownloadPdf({ apiMethod: 'post', url: 'pdf-ms-trans-download', body: bodyFormData });
  }

  const handleDownloadXlsx = () => {
    const bodyFormData = new URLSearchParams();
    bodyFormData.append('print_date', true);
    bodyFormData.append('search', search);
    bodyFormData.append('tag_number', 14);
    bodyFormData.append('filter', JSON.stringify(filter));
    DownloadXlsx({ apiMethod: 'post', url: 'xlsx-ms-trans-download', body: bodyFormData });
  }


  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleOpen = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  return (
    <div className={`main-wrapper ${isSidebarOpen ? "slide-nav" : ""}`}>
      <Header handleOpen={handleOpen} />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/main-store/user/dashboard">Dashboard </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right"></i>
                  </li>
                  <li className="breadcrumb-item active">Issue Return </li>
                </ul>
              </div>
            </div>
          </div>
          {disable === false ? (
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Issue Return</h3>
                            <div className="doctor-search-blk">
                              <div className="top-nav-search table-search-blk">
                                <form>
                                  <Search
                                    onSearch={(value) => {
                                      setSearch(value);
                                      setCurrentPage(1);
                                    }}
                                  />
                                  {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                  <a className="btn">
                                    <img
                                      src="/assets/img/icons/search-normal.svg"
                                      alt="firm-searchBox"
                                    />
                                  </a>
                                </form>
                              </div>
                              <div className="add-group">
                                <Link to="/main-store/user/manage-purchase-issue-return" className="btn btn-primary add-pluss ms-2"
                                  data-toggle="tooltip" data-placement="top" title="Add" >
                                  <img src="/assets/img/icons/plus.svg" alt="plus-icon" />
                                </Link>
                                <button type="button" onClick={handleRefresh} className="btn btn-primary doctor-refresh ms-2"
                                  data-toggle="tooltip" data-placement="top" title="Refresh">
                                  <img src="/assets/img/icons/re-fresh.svg" alt="refresh" />
                                </button>
                              </div>
                              <div className='add-group mx-2'>
                                <div className="input-block local-forms mb-0">
                                  <label>Start Date</label>
                                  <input type="date" className='form-control'
                                    onChange={(e) => handleDateChange(e, 'start')} />
                                </div>
                              </div>
                              <div className=''>
                                <div className="input-block local-forms mb-0">
                                  <label>End Date</label>
                                  <input type="date" className='form-control'
                                    onChange={(e) => handleDateChange(e, 'end')} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="pageDropDown col-auto text-end float-end ms-auto download-grp">
                          <div className='add-group'>
                            <button className='btn w-100 btn btn-primary doctor-refresh me-2 h-100' type='button' onClick={handleDownloadAllPdf}>PDF <i className="fa-solid fa-download mx-2"></i></button>
                          </div>
                          <div className='add-group'>
                            <button className='btn w-100 btn btn-primary doctor-refresh me-2 h-100' type='button' onClick={handleDownloadXlsx}>XLSX <i className="fa-solid fa-download mx-2"></i></button>
                          </div>
                          <DropDown
                            limit={limit}
                            onLimitChange={(val) => setlimit(val)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="table-responsive">
                      <table className="table border-0 custom-table comman-table  mb-0">
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th className="right-align">Issue Date</th>
                            <th className="right-align" >Voucher No.</th>
                            <th className="right-align" >Bill No.</th>
                            <th>Party Name</th>
                            <th>Project Name </th>
                            <th className="right-align" >Items</th>
                            <th className="text-end">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {commentsData?.map((elem, i) =>
                            <tr key={elem?._id}>
                              <td>{(currentPage - 1) * limit + i + 1}</td>
                              <td className="right-align">{moment(elem.order_date).format('YYYY-MM-DD')}</td>
                              <td className="right-align">{elem.voucher_no}</td>
                              <td className="right-align">{elem.bill_no}</td>
                              <td>{elem?.party?.name}</td>
                              <td>{elem?.project?.name}</td>
                              <td className="right-align">{elem.items_details_count}</td>
                              <td className="text-end">
                                <div className="dropdown dropdown-action">
                                  <a href="#" className="action-icon dropdown-toggle"
                                    data-bs-toggle="dropdown" aria-expanded="false"><i
                                      className="fa fa-ellipsis-v"></i></a>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <button type='button' className="dropdown-item" onClick={() => navigate(`/main-store/user/view-issue-return`, { state: elem })}><i
                                      className="fa-solid fa-eye m-r-5"></i>
                                      View</button>
                                    <button type='button' className="dropdown-item" onClick={() => navigate('/main-store/user/edit-issue-return-manage', { state: elem })}><i
                                      className="fa-solid fa-pen-to-square m-r-5"></i>
                                      Edit</button>
                                    <button type='button' className="dropdown-item" onClick={() => handleDelete(elem?._id, elem.name)} ><i
                                      className="fa fa-trash-alt m-r-5"></i> Delete</button>
                                    <button type='button' className="dropdown-item" onClick={() => handleDownloadPdf(elem?._id)}><i
                                      className="fa-solid fa-download m-r-5"></i> Download Report</button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}

                          {commentsData?.length === 0 ? (
                            <tr>
                              <td colSpan="999">
                                <div className="no-table-data">
                                  No Data Found!
                                </div>
                              </td>
                            </tr>
                          ) : null}
                        </tbody>
                      </table>
                    </div>
                    <div className="row align-center mt-3 mb-2">
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6">
                        <div className="dataTables_info" id="DataTables_Table_0_info" role="status"
                          aria-live="polite">Showing {Math.min(limit, totalItems)} from {totalItems} data</div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6 ">
                        <div className="dataTables_paginate paging_simple_numbers"
                          id="DataTables_Table_0_paginate">
                          <Pagination
                            total={totalItems}
                            itemsPerPage={limit}
                            currentPage={currentPage}
                            onPageChange={(page) => setCurrentPage(page)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : <Loader />}
        </div>
      </div>
      <OrderModal
        show={show}
        handleClose={handleClose}
        handleSaveModal={handleSaveModal}
        selectedData={selectedData}
        itemApiData={itemApiData}
      />
    </div>
  )
}


export default IssuePurchaseReturn